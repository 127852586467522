/**
 * pdf preview related actions
 */

import Reflux from 'reflux';
import { dataInterface } from 'js/dataInterface';


const pdfPreviewActions = Reflux.createActions({
  uploadTemplateFile: {children: ['completed', 'failed']},
  getPdf: {children: ['completed', 'failed']},
  getTemplates: {children: ['completed', 'failed']},
});

/**
 * Actions for uploading template file
 */

pdfPreviewActions.uploadTemplateFile.listen((options) => {
  dataInterface
    .uploadTemplate(options.url, options.projectId, options.file)
    .done((response) => {
      pdfPreviewActions.uploadTemplateFile.completed(response, options);
    })
    .fail((response) => {
      pdfPreviewActions.uploadTemplateFile.failed(response, options);
    });
});

/**
 * Actions for getting a pdf file for the submission
 */

pdfPreviewActions.getPdf.listen((options) => {
  dataInterface
    .previewPdf(options.url, options.data, options.project_name)
    .then((response) => {
      pdfPreviewActions.getPdf.completed(response, options);
    })
    .catch((error) => {
      pdfPreviewActions.getPdf.failed(error, options);
    });
});
/**
 * Actions for getting all the templates
 */

pdfPreviewActions.getTemplates.listen((options) => {
  dataInterface
    .getTemplates(options.url)
    .done((response) => {
      pdfPreviewActions.getTemplates.completed(response, options);
    })
    .fail((response) => {
      pdfPreviewActions.getTemplates.failed(response, options);
    });
});

export default pdfPreviewActions;
