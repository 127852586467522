import React from 'react';
import PropTypes from 'prop-types';
import reactMixin from 'react-mixin';
import autoBind from 'react-autobind';
import Reflux from 'reflux';
import Dropzone from 'react-dropzone';
import bem from 'js/bem';
import LoadingSpinner from 'js/components/common/loadingSpinner';
import {stores} from 'js/stores';
import mixins from 'js/mixins';
import {renderBackButton} from './modalHelpers';
import {validFileTypes} from 'utils';
import {ASSET_TYPES} from 'js/constants';
import {dataInterface} from '../../dataInterface.es6';
import {MODAL_TYPES} from '../../constants';
import {notify} from 'js/utils';
import envStore from 'js/envStore';
import {actions} from '../../actions';

const DESIRED_TYPES = [
  {
    value: ASSET_TYPES.block.id,
    label: ASSET_TYPES.block.label,
  },
  {
    value: ASSET_TYPES.template.id,
    label: ASSET_TYPES.template.label,
  },
];

/**
 * @prop {function} onSetModalTitle
 * @prop {file} [file] optional preloaded file
 */
class TemplateUploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSessionLoaded: !!stores.session.isLoggedIn,
      isPending: false,
      // default is block
      desiredType: DESIRED_TYPES[0],
      currentFile: this.props.file || null,
    };

    autoBind(this);
  }

  componentDidMount() {
    this.listenTo(stores.session, () => {
      this.setState({isSessionLoaded: true});
    });

    this.listenTo(
      actions.pdfPreview.uploadTemplateFile.completed,
      this.uploadFileCompleted
    );

    this.listenTo(
      actions.pdfPreview.uploadTemplateFile.failed,
      this.uploadFileFailed
    );

    console.log(this.props);
  }

  isSubmitEnabled() {
    return !this.state.isPending && this.state.currentFile !== null;
  }

  onFileDrop(files) {
    if (files[0]) {
      this.setState({currentFile: files[0]});
    }
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.setState({isPending: true});

    actions.pdfPreview.uploadTemplateFile({
      url: envStore.data.pdf_preview_url,
      projectId: this.props.projectId,
      file: this.state.currentFile,
    });
  }

  uploadFileCompleted(response) {
    this.setState({isPending: false});
    this.props.onUploadSuccess();
  }

  uploadFileFailed() {
    notify(t('Failed to upload template'), 'error');
    this.props.onUploadSuccess();
  }

  render() {
    if (!this.state.isSessionLoaded) {
      return <LoadingSpinner />;
    }

    return (
      <bem.FormModal__form className='project-settings'>
        <bem.Modal__subheader>
          {t('Import DOCX file from your computer')}
        </bem.Modal__subheader>

        {!this.state.isPending && (
          <React.Fragment>
            <bem.FormModal__item>
              {this.props.message && (
                <p data-cy="service-unavailable" style={{fontSize: '1.5rem', color: 'red', padding: '1rem', lineHeight:'2'}}>
                  {this.props?.message}
                </p>
              )}
              {!this.props.message && (
                <Dropzone
                  onDrop={this.onFileDrop.bind(this)}
                  multiple={false}
                  className='dropzone'
                  activeClassName='dropzone-active'
                  rejectClassName='dropzone-reject'
                  accept={'.docx'}
                >
                  <i className='k-icon k-icon-file' />
                  {this.state.currentFile && this.state.currentFile.name}
                  {!this.state.currentFile &&
                    t(' Drag and drop the DOCX file here or click to browse')}
                </Dropzone>
              )}
            </bem.FormModal__item>
          </React.Fragment>
        )}
        {this.state.isPending && (
          <div className='dropzone'>
            <LoadingSpinner message={t('Uploading file…')} />
          </div>
        )}

        <bem.Modal__footer>
          {/* {renderBackButton(this.state.isPending)} */}

          <bem.KoboButton
            m='blue'
            type='submit'
            onClick={this.onSubmit}
            disabled={!this.isSubmitEnabled()}
          >
            {t('Upload')}
          </bem.KoboButton>
        </bem.Modal__footer>
      </bem.FormModal__form>
    );
  }
}

reactMixin(TemplateUploadForm.prototype, Reflux.ListenerMixin);
reactMixin(TemplateUploadForm.prototype, mixins.droppable);
TemplateUploadForm.contextTypes = {router: PropTypes.object};

export default TemplateUploadForm;
