import React from 'react';
import autoBind from 'react-autobind';
import Reflux from 'reflux';
import reactMixin from 'react-mixin';
import envStore from 'js/envStore';
import {actions} from 'js/actions';
import LoadingSpinner from 'js/components/common/loadingSpinner';

const DETAIL_NOT_FOUND = '{"detail":"Not found."}';
const url = envStore.data.pdf_preview_url;

class PdfModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      previewUrl: null,
      pageNumber: 1,
      numPages: null,
    };

    this.unlisteners = [];

    autoBind(this);
  }

  componentDidMount() {
    this.unlisteners.push(
      actions.pdfPreview.getPdf.completed.listen(this.onGetPdfComplete)
    );
  }

  componentWillUnmount() {
    this.unlisteners.forEach((clb) => {
      clb();
    });
  }

  onGetPdfComplete(response) {
    let previewerByteArray = new Uint8Array(response.data);

    const file = new Blob([previewerByteArray], {
      type: 'application/pdf',
    });

    const fileURL = URL.createObjectURL(file);

    this.setState({
      loading: false,
      previewUrl: fileURL,
    });
  }

  onDocumentLoadSuccess({numPages}) {
    this.setState({numPages: numPages});
  }

  render() {
    return (
      <div style={{height: '100%', width: '100%'}}>
        {this.state.loading && <LoadingSpinner />}
        {this.state.previewUrl && (
          <iframe
            allowTransparency='true'
            style={{
              height: 'inherit',
              background: '#FFFFFF',
              frameborder: '0',
              border: 'outset',
            }}
            src={this.state.previewUrl}
          />
        )}
      </div>
    );
  }
}

reactMixin(PdfModal.prototype, Reflux.ListenerMixin);

export default PdfModal;
